<template>
  <div class="about">
    <h1 class="titulo_principal">Acerca de la FUNDACIÓN INFOCAL POTOSÍ</h1>
    <b-container>
      <div>
    <b-card no-body>
      <b-tabs v-model="tabIndex" card>
        <b-tab title="OBJETIVO GENERAL" :title-link-class="linkClass(0)">Contribuir al mejoramiento de la competitividad de las empresas, a través de la profesionalización técnica y capacitación laboral de sus empleados.</b-tab>
        <b-tab title="MISIÓN" :title-link-class="linkClass(1)">Formar profesionales técnicos de excelencia, a través de la calidad, actualización e innovación de nuestros servicios pedagógicos en base a un trabajo en equipo, siguiendo la filosofía humanista con docentes y estudiantes para consolidarnos como la institución líder en formación y capacitación, comprometidos con el desarrollo socio económico del País.</b-tab>
        <b-tab title="VISIÓN" :title-link-class="linkClass(2)">Ser la Institución líder en formación técnica de excelencia a nivel nacional, que se distinga por los mejores índices de calidad llegando a cubrir todas las necesidades de formación y capacitación laboral, utilizando metodologías pedagógicas actualizadas, para lograr profesionales altamente competitivos.</b-tab>
      </b-tabs>
    </b-card>
  </div>
    </b-container>

 
<h2> DIRECTORIO </h2>



       <b-container fluid class="p-4 bg-light">
        <b-row class="text-center">
          <b-col md class="mt-4 mb-4">
            <P class="nombre">Lic. Sebastian Benjamin Careaga Campos</P>
            <p class="cargo">PRESIDENTE DE DIRECTORIO</p>
          </b-col>
          
        </b-row>
        <b-row class="text-center">
          <b-col md class="mt-4 mb-4">
            <P class="nombre">Lic. Reynaldo Julian Cors Careaga</P>
            <p class="cargo">REPRESENTANTE EMPRESA PRIVADA</p>
          </b-col>
          <b-col md class="mt-4 mb-4">
            <P class="nombre">Lic. Roye Enrique Garabito Calvetty</P>
            <p class="cargo">REPRESENTANTE EMPRESA PRIVADA</p>
          </b-col>
         </b-row>
        <b-row class="text-center">
          <b-col md class="mt-4 mb-4">
            <P class="nombre">Sr. Jorge Solares Barrientos</P>
            <p class="cargo">REPRESENTANTE COD</p>
          </b-col>
          <b-col md class="mt-4 mb-4">
            <P class="nombre">Sr. Oscar Fe Valda Pereira</P>
            <p class="cargo">REPRESENTANTE COD</p>
          </b-col>
         </b-row>
      </b-container>
  </div>
</template>
<script>
export default {
    data() {
      return {
        tabIndex: 0
      }
    },
    methods: {
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['bg-primary', 'text-white']
        } else {
          return ['bg-secondary', 'text-info']
        }
      }
    }
  }
</script>

<style scoped>

.titulo_principal {
    padding: 3rem 1rem;
    text-align: center;
    color: #2956b2;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.5rem;

    font-family: Montserrat,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;
}

h2{
    border-radius: 8px;
    box-shadow: 0 12px 20px -10px rgb(255 90 25 / 28%), 0 4px 20px 0 rgb(0 0 0 / 12%), 0 7px 8px -5px rgb(255 90 25 / 20%);
    background: #0d6efd;
    background: linear-gradient(0deg,#0d6efd,#5689cf); 
    width: 95%;
    text-align: center;
    margin: auto;
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 1rem 0.5rem; 
    font-size: 1rem;
    font-weight: 400;
    
    font-weight: 500;
    color: #e5eee9;
} 
.nombre{
  color:#2956b2;
  font-size: 1.2rem;
}
.cargo{
  color:#082358;
  font-size: 0.8rem;
}
</style>